import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Container, } from '@material-ui/core';
import { ValueCard } from './ValueCard';
import useMediaQuery from '@material-ui/core/useMediaQuery';


const useStyles = makeStyles((theme) => ({
	root: {
		color: theme.workwaveBlue,
		padding: '0px',
		borderRadius: '20px',
		minHeight: '210px',
		boxShadow: '0 10px 40px 0px rgba(0,0,0,0.15)',
		position: 'relative',
		transition: 'transform 0.25s',
    [theme.breakpoints.down('md')]: {
			maxWidth: '500px',
		},
	},
  valueHeader: {
		fontWeight: 700,
		color: theme.workwaveBlue,
		marginBottom: '2rem',
		textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      textAlign: 'left',
    },
	},
}));

export const ValueCards = ({ header, cards }) => {
	const classes = useStyles();

	return (
    <>
      <Container>
        <Grid container direction='row' justify='center' alignItems='center'>
          <Grid item xs={12} md={8}>
            <Typography variant='h2' className={classes.valueHeader}>
              {header}
            </Typography>
          </Grid>
        </Grid>
        {cards.map((card, index) => (
          <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
            spacing={2}>
              <ValueCard key={index} card={card} />
          </Grid>
        ))}
      </Container>
    </>
  );
};