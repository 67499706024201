import React from 'react';
import PortableText from 'react-portable-text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Card, CardContent } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
	root: {
		color: theme.workwaveBlue,
		padding: '0px',
		marginBottom: '1rem',
		borderRadius: '20px',
		minHeight: '210px',
		boxShadow: '0 10px 40px 0px rgba(0,0,0,0.15)',
		position: 'relative',
		transition: 'transform 0.25s',
		[theme.breakpoints.down('md')]: {
			maxWidth: '500px',
		},
	},
	cardTitle: {
		fontSize: '1.625rem',
		fontWeight: 700,
		textAlign: 'left',
		color: theme.workwaveBlue,
		marginLeft: '.5rem',
		lineHeight: 1.3,
		[theme.breakpoints.down('xs')]: {
			marginLeft: '1rem',
			fontSize: '1.3rem',
		},
	},
	pos: {
		textAlign: 'left',
		lineHeight: 1.5,
		color: '#4B5B69',
		[theme.breakpoints.down('xs')]: {
			fontSize: '1rem',
		},
	},
	header: {
		padding: '1rem 0',
		[theme.breakpoints.down('xs')]: {
			fontSize: '1.2rem',
		},
	},
}));

export const ValueCard = ({ card }) => {
	const classes = useStyles();
	const { faIcon, title, _rawBlurb, accentColor } = card;

	return (
		<Grid
			item
			xs={12}
			container
			direction='column'
			justifyContent='center'
			alignItems='center'>
			<Card className={classes.root} elevation={0}>
				<CardContent
					style={{
						borderTop: `16px solid ${accentColor.hexValue ?? '#002D5C'}`,
						minHeight: '210px',
						display: 'flex',
						flexDirection: 'column',
						padding: '24px',
						alignItems: 'center',
						justifyContent: 'center',
					}}>
					<Grid
						item
						container
						direction='row'
						justifyContent='flex-start'
						alignItems='center'
						style={{ flexWrap: 'nowrap' }}>
						<Grid item>
							<FontAwesomeIcon
								icon={['fas', faIcon]}
								style={{
									color: accentColor.hexValue ?? '#002D5C',
									height: '60px',
									width: '60px',
									padding: '10px',
									textAlign: 'center',
								}}
							/>
						</Grid>
						<Grid item>
							<Typography variant='h6' className={classes.cardTitle}>
								{title}
							</Typography>
						</Grid>
					</Grid>
					<Grid
						container
						item
						direction='row'
						justifyContent='flex-start'
						alignItems='flex-start'
						style={{ marginTop: '16px' }}>
						<PortableText
							content={_rawBlurb}
							serializers={{
								normal: ({ children }) => (
									<Typography variant='body1' className={classes.pos}>
										{children}
									</Typography>
								),
								h3: ({ children }) => (
									<Typography variant='h5' className={classes.header}>
										{children}
									</Typography>
								),
							}}
						/>
					</Grid>
				</CardContent>
			</Card>
		</Grid>
	);
};
